<template>
  <a-drawer
    title=""
    :closable="false"
    :visible="visible"
    :width="720"
    @close="onClose"
  >
    <div class="table-operations mb-3">
      <a-button v-if="cart.length" key="ok" type="primary" @click="onClear">
        <template #icon>
          <CloseOutlined />
        </template>
        Очистить
      </a-button>
    </div>
    <div style="display: flex; flex-direction: column; height: 100%; justify-content: space-between">
      <div style="height: 500px; overflow-y: auto">
        <a-table
          :columns="columns"
          :data-source="cart"
          :loading="loading"
          :pagination="false"
          @change="tableChange"
          row-key="id"
        >
          <template #big_img_url="{ record }">
            <div class="cart-image">
              <img
                :src="record.item?.bigImageUrl || '/icons/food-placeholder.png'"
                alt=""
              />
            </div>
          </template>
          <template #name="{ record }">
        <span>
          {{ record.item.name ?? "-" }}
        </span>
          </template>
          <template #price="{ record }">
        <span>
          {{ formattedBalance(record.item.price) }}
        </span>
          </template>
          <template #amount="{ record }">
            <b>
              {{ formattedBalance(record.item.price * record.count) }}
            </b>
          </template>
          <template #action="{ record }">
            <div class="d-flex justify-content-center align-items-center">
                <a-button class="btn-action" @click="decrease(record)" style="" type="primary">
                  <i class="fe fe-minus" v-if="inCart(record)?.count > 1"></i>
                  <i class="fe fe-x" v-else></i>
                </a-button>
                <span
                  class="product-count mx-1 text-center"
                  style="width: 25px"
                >{{ inCart(record).count }}</span>
                <a-button class="btn-action" @click="increase(record, 1)" type="primary">
                  <i class="fe fe-plus"></i>
                </a-button>
              </div>
          </template>
        </a-table>
      </div>
      <div v-if="cart.length" class="position-absolute phone-link">
        <a-input
          @input="updatePhone($event.target.value)"
          :value="phoneInput"
          v-maska="'+998(##) ###-##-##'"
          placeholder="Введите номер телефона"
        />
      </div>
    </div>
    <div v-if="cart.length" class="drawer-bottom d-flex align-items-center justify-content-between">
      <div>
        <div class="">Общая стоимость: {{ totalCost }}</div>
        <div class="">Стоимость доставки: {{ formattedBalance(deliveryCost) }}</div>
        <div class="font-weight-bold">Итого: {{ total }}</div>
      </div>
      <div class="d-flex">
        <a-button style="margin-right: 8px" type="primary" @click="onShowQr">QR-code</a-button>
        <a-button style="margin-right: 8px" type="primary" :loading="loading" :disabled="loading" @click="onConfirm">Подтвердить</a-button>
        <a-button @click="onClose">Отмена</a-button>
      </div>
    </div>
  </a-drawer>
</template>

<script setup>
import { watch, ref, toRefs, computed } from "vue";
import { useStore } from "vuex";
import { mask } from "maska";
import { CloseOutlined } from '@ant-design/icons-vue';

const props = defineProps({
  openDrawer: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
});
const
  store = useStore(),
  { openDrawer } = toRefs(props),
  visible = ref(false),
  cart = computed(() => store.getters["cashier/getCart"]),
  emit = defineEmits(["hide-drawer", "confirm", "set-phone", "show-qrcode"]),
  columns = [
    {
      title: "Картинка",
      dataIndex: "big_img_url",
      key: "big_img_url",
      sorter: true,
      slots: {
        customRender: "big_img_url",
      },
    },
    {
      title: "Название",
      dataIndex: "name",
      sorter: true,
      key: "name",
      slots: { customRender: "name"},
    },
    {
      title: "Цена",
      dataIndex: "price",
      sorter: true,
      key: "price",
      slots: { customRender: "price" },
    },
    {
      title: "Сумма",
      dataIndex: "amount",
      sorter: true,
      key: "amount",
      slots: { customRender: "amount" },
    },
    {
      key: "action",
      slots: {
        filterIcon: "filterIcon",
        customRender: "action",
      },
    },
  ],
  deliveryCost = ref(20000),
  phoneInput = ref('998'),
  phone = ref('998'),
  totalCost = computed(() => {
    let total = 0;
    cart.value.forEach((obj) => {
      total += obj.item.price * obj.count;
    })
    return formattedBalance(total);
  }),
  total = computed(() => {
    let total = 0;
    cart.value.forEach((obj) => {
      total += obj.item.price * obj.count;
    })
    return formattedBalance(total + deliveryCost.value);
  }),
  orderSum = computed(() => {
    let total = 0;
    cart.value.forEach((obj) => {
      total += obj.item.price * obj.count;
    })
    return total
  }),
  decrease = ({ item }) => {
    store.dispatch("cashier/removeFromCart", { item, all: false });
  },
  increase = ({ item }, count) => {
    store.dispatch("cashier/addToCart", { item, count });
  },
  updatePhone = (input) => {
    let x = input
      .replace(/\D/g, "")
      .match(/(\d{0,3})(\d{0,2})(\d{0,3})(\d{0,2})(\d{0,2})/);
    phoneInput.value = input
    phone.value = x[0];
    emit("set-phone", phone.value);
  },
  inCart = ({ item }) => {
    return store.getters["cashier/getCart"].find((cart) => cart?.item?.id === item.id);
  },
  onClear = () => {
    store.dispatch("cashier/clearCart")
  },
  formattedBalance = (balance, penny = false) => {
    let formatter = new Intl.NumberFormat("RU", {
      style: "currency",
      currency: "UZS",
    });
    let actualBalance = penny ? balance / 100 : balance;
    let formattedBalance = formatter.format(actualBalance);
    return formattedBalance.replace(",00", "");
  },
  formattedPhone = (phone) => {
    return (phone) ? mask(phone, '+998(##) ###-##-##') : ''
  },
  onClose = () => {
    visible.value = false;
    emit("hide-drawer");
  },
  onConfirm = () => {
    emit("confirm", orderSum.value);
  },
  onShowQr = () => {
    emit("show-qrcode", orderSum.value);
  };

  watch(openDrawer, (value) => {
    visible.value = value;
  });
</script>

<style lang="scss">
.drawer-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e8e8e8;
  padding: 10px 16px;
  left: 0;
  background: #fff;
  border-radius: 0 0 4px 4px;
}

.cart {
  border: 1px solid #eee;
  margin-bottom: 5px;

  &-image {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      border-radius: 5px;
      object-fit: contain;
      background: #fff;
    }
  }

  &-title {
    font-size: 16px;
    margin-left: 12px;
    max-width: 240px;
  }

  &-price {
    font-size: 16px;
    margin-left: 12px;
  }
}

.phone-link {
  bottom: 100px;
  left: 15px;
}
</style>
