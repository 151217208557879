<template>
    <a-card
      v-for="record in product.items"
      :key="record.id"
      class="px-0 mb-4 mx-2"
      hoverable
      style="width: 300px"
    >
      <template #cover>
        <img
          height="150"
          alt="There has image"
          class="cashier-img"
          :src="record.bigImageUrl || '/icons/food-placeholder.png'"
        />
      </template>
      <a-card-meta :title="record.name">
        <template #description>
          <p class="mt-n2 ">{{ formattedBalance(record.price) }}</p>
        </template>
      </a-card-meta>
      <div class="d-flex justify-content-center">
        <a-button v-if="!inCart(record)" @click="increase(record, 1)" class="btn-add" type="primary">
          <strong>Добавить</strong>
        </a-button>
        <div v-else class="d-flex justify-content-center align-items-center product-count-wrapper">
          <a-button class="btn-action" @click="decrease(record)" style="" type="primary">
            <i class="fe fe-minus" v-if="inCart(record)?.count > 1"></i>
            <i class="fe fe-x" v-else></i>
          </a-button>
          <span
            class="product-count mx-1"
          >{{ inCart(record).count }} шт.</span>
          <a-button class="btn-action" @click="increase(record, 1)" type="primary">
            <i class="fe fe-plus"></i>
          </a-button>
        </div>
      </div>
    </a-card>
</template>

<script setup>
import { useStore } from "vuex";

const props = defineProps({
  product: {
    type: Object,
    default: () => {
    },
  },
});

const
  store = useStore(),
  decrease = (item) => {
    store.dispatch("cashier/removeFromCart", { item, all: false });
  },
  increase = (item, count) => {
    store.dispatch("cashier/addToCart", { item, count });
  },
  inCart = (item) => {
    return store.getters["cashier/getCart"].find((cart) => cart?.item?.id === item.id);
  },
  formattedBalance = (balance, penny = false) => {
    let formatter = new Intl.NumberFormat('RU', {
      style: 'currency',
      currency: 'UZS',
    });
    let actualBalance = penny ? balance / 100 : balance;
    let formattedBalance = formatter.format(actualBalance)
    return formattedBalance.replace(",00", "");
  };
</script>

<style scoped>
.cashier-img {
  object-fit: contain;
}
.btn-action {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.btn-add {
  height: 40px;
  width: 100%;
}
.product-count-wrapper {
  width: 100%;
  gap: 15px;
}
.product-count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 40px;
  background-color: #f5f5f5;
  border-radius: 10px;
  font-size: 16px;
}
</style>
