<template>
  <div>
    <div class="row mb-5">
      <div class="col-md-3">
        <a-select
          v-model:value="brandId"
          :options="brands"
          :option-filter-prop="'label'"
          placeholder="Выберите бренд"
          style="width: 100%"
          @change="searchBrand"
          show-search
        />
      </div>
      <div class="col-md-3">
        <a-select
          v-model:value="storeId"
          :options="stores"
          :option-filter-prop="'label'"
          placeholder="Выберите филиал"
          style="width: 100%"
          @change="searchStore"
          show-search
        />
      </div>
      <div class="col-md-3">
        <a-input
          v-model:value="name"
          @change="searchByName"
          placeholder="Поиск по названию"
          allow-clear
        />
      </div>
      <div class="col-md-3">
        <a-button @click="showDrawer" class="cart w-100" type="primary">
          Выбранные товары <span class="ml-1"> ({{ cart.length }})</span>
        </a-button>
      </div>
      <div class="text-right col-md-6">
        <!--<vb-create-employee :roles="roles" :stores="stores" @reload="reload()" />-->
      </div>
    </div>
    <div v-if="loading" class="w-100 p-5 text-center">
      <a-spin />
    </div>
    <div v-else>
      <div v-if="products.length" class="m-n3">
        <div class="p-3" v-for="product in products" :key="product.id">
          <h2 class="mb-3">{{product.name}}</h2>
          <div class="d-flex flex-wrap" style="margin: 0 -.5em">
            <Card :product="product"/>
          </div>
        </div>
      </div>
      <div v-else>
        <h3 class="text-center">Нет информации</h3>
      </div>
    </div>
    <Drawer
      :open-drawer="openDrawer"
      :loading="loading"
      @hide-drawer="hideDrawer"
      @confirm="confirmOrder"
      @show-qrcode="showQRCode"
      @set-phone="setPhone"
    />
    <a-modal v-model:visible="modalVisible" @ok="modalHandleOk">
      <h3 class="text-center">QR-code</h3>
      <img class="mx-auto d-block" :src="qrCodeLink" alt="">
      <template v-if="qrCodeLink" #footer>
        <a-button key="ok" shape="round" type="primary" @click="shareQrCode">
          <template #icon>
            <CopyOutlined />
          </template>
          Скопировать ссылку
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script setup>
import { computed, onMounted, reactive, ref, toRaw, toRefs, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import apiClient from "../../services/axios";
import Card from "./components/card";
import Drawer from "./components/drawer";
import {CopyOutlined} from '@ant-design/icons-vue';
import { notification } from "ant-design-vue";

const
  store = useStore(),
  router = useRouter(),
  route = useRoute(),
  orderLink = ref(null),
  loading = ref(false),
  openDrawer = ref(false),
  modalVisible = ref(false),
  qrCodeLink = ref(""),
  name = ref(""),
  brands = ref([]),
  stores = ref([]),
  storeId = ref(null),
  brandId = ref(null),
  total = ref(0),
  phone = ref(null),
  initialParams = {
    page: 1,
    "per-page": 10,
    sort: "",
    name: "",
    store: "",
  },
  queryParams = reactive({ ...initialParams }),
  products = ref([]),
  confirmLoading = ref(false),
  cart = computed(() => store.getters["cashier/getCart"]);

const
  showDrawer = () => {
    openDrawer.value = true;
  },
  hideDrawer = () => {
    openDrawer.value = false;
  },
  confirmOrder = (total) => {
    if (!storeId.value) {
      notification.warning({
        message: 'Флиал не найден',
        description: "Сначала выберите филиал",
      });
      return;
    }
    loading.value = true;
    const items = toRaw(cart.value);
    return apiClient.post(`/menu/order-create?store=${storeId.value}`, {
      items,
      "phone": phone.value,
      "type": "invoice",
      "amount": total
    }).then((res) => {
      if (res) {
        orderLink.value = res?.data?.data?.['checkout_url']
        notification.success({
          message: 'Подтверждено!',
        });
        store.dispatch("cashier/clearCart");
      }
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  showQRCode = (total) => {
    confirmOrder(total).finally(() => {
      const currentLink = orderLink.value;
      qrCodeLink.value = `https://chart.googleapis.com/chart?cht=qr&chs=300x300&chl=${currentLink}&choe=UTF-8`;
      modalVisible.value = true;
    })
  },
  shareQrCode = () => {
    navigator.clipboard.writeText(orderLink.value);
    notification.success({
      message: 'Successfully Copied',
    });
  },
  setPhone = (value) => {
    phone.value = value;
  },
  modalHandleOk = () => {
    modalVisible.value = false;
  },
  updateParams = params => {
    Object.assign(queryParams, params);
  },
  searchBrand = (e) => {
    storeId.value = null;
    phone.value = null;
    products.value = [];
    store.dispatch("cashier/clearCart");
    updateParams({brand: e})
    getBrandStoreData(e)
  },
  searchByName = (e) => {
    updateParams({name: e.target.value})
  },
  searchStore = (e) => {
    phone.value = null;
    products.value = [];
    store.dispatch("cashier/clearCart");
    updateParams({store: e})
  },
  getData = (params) => {
    if (params.store.length > 0) {
      loading.value = true
      return apiClient.get('/menu/products-list', { params }).then((res) => {
        if (res) {
          products.value = res.data.data?.result;
        }
      }).catch(() => {
        // console.log(err)
      }).finally(() => {
        loading.value = false
      });
    }
  },
  getBrandStoreData = (brand) => {
    return apiClient.get('/menu/references?brand=' + brand).then(({data}) => {
      brands.value = data.data?.brands;
      stores.value = data.data?.stores;
    }).catch(() => {
      // console.log(err)
    }).finally(() => {
      loading.value = false
    });
  },
  reload = () => {
    getData({
      ...queryParams,
    })
  };

  onMounted(() => {
    if (route.query.brand) {
      brandId.value = route.query.brand
      updateParams({ brand: route.query.brand })
      getBrandStoreData(brandId.value);
    } else {
      getData({
        ...queryParams,
      });
      getBrandStoreData(null);
    }
  })

  watch(queryParams, () => {
    router.push({
      name: "order",
      query: {
        ...route.query,
        ...queryParams,
      },
    });
    getData({
      ...route.query,
      ...queryParams,
    });
  });

const query = {...toRefs(queryParams)}
</script>

<style>
@import "index.css";
</style>
